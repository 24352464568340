<template>
  <NuxtLayout name="base">
    <div class="base">
      <div class="container-dash">
        <div class="focus">
          <div class="centered">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use 'pkg:@temperworks/styles/colors' as *;
@use 'pkg:@temperworks/styles/breakpoints' as *;
@use 'pkg:@temperworks/styles/sizes' as *;

.base {
  background: $grey100;
  margin-top: 56px;
  min-height: calc(100vh - 56px);
  padding: env(safe-area-inset-bottom, $doubleOff);

  @include tab {
    margin-top: 64px;
    min-height: calc(100vh - 64px);
    padding: env(safe-area-inset-bottom, $none);
  }
}

.container-dash {
  margin: $none;
  padding: $none $doubleOff;
  background: $bright;
  width: 100%;

  @include sm {
    padding: $none $quadruple;
  }

  @include md {
    padding: $none $sixfold;
  }

  @include tab {
    margin: $none $sixfold;
    padding: $none;
    width: auto;
    background: $transparent;
  }

  @include lg {
    margin: $none 64px;
  }

  @include xl {
    margin: $none 104px;
  }
}

.focus {
  width: 100%;
  background: $bright;
  margin-left: auto;
  margin-right: auto;

  @include md {
    width: calc((100% / 12) * 10);
    max-width: 1000px;
  }

  @include xl {
    max-width: calc((100% / 12) * 8);
  }
}

.centered {
  width: 100%;
  min-height: calc(100vh - 56px);
  margin-left: auto;
  margin-right: auto;

  @include tab {
    width: calc((100vw / 12) * 8);
    max-width: 720px;
    min-height: calc(100vh - 64px);
  }

  @include lg {
    width: calc((100vw / 12) * 6);
  }

  @include xl {
    width: calc((100vw / 12) * 5);
  }
}
</style>
